import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WebTipComponent } from '@app/components/humi-design-library/tip/tip.component';
import { TranslateModule } from '@ngx-translate/core';
import { VideoPreviewService } from '@videos/services';
import { VideoMetadata } from '../../services/types';

@Component({
    selector: 'app-video-preview',
    templateUrl: './video-preview.template.html',
    styleUrls: ['./video-preview.style.scss'],
    standalone: true,
    imports: [TranslateModule, WebTipComponent],
})
export default class VideoPreviewComponent {
    @Input() videoMetadata?: VideoMetadata;
    @Input() set videoUrl(videoUrl: string | undefined) {
        if (videoUrl) {
            this.loadMeta(videoUrl);
        }
    }
    @Output() onVideoLoad = new EventEmitter<VideoMetadata>();
    isLoading = false;

    constructor(private previewService: VideoPreviewService) {}

    private loadMeta(videoUrl: string): void {
        this.isLoading = true;
        this.previewService.lookupByUrl(videoUrl).subscribe({
            next: (metaData) => {
                this.videoMetadata = metaData;
                this.onVideoLoad.emit(metaData);
                this.isLoading = false;
            },
            error: () => {
                this.isLoading = false;
            },
        });
    }
}
