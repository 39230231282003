import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import InvalidVideoUrlError from '@videos/errors/invalid-video-url.error';
import VideoNotFoundError from '@videos/errors/video-not-found.error';
import VideoPreviewServiceUnavailableError from '@videos/errors/video-preview-service-unavailable.error';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { VideoMetadata } from './types';

@Injectable()
export default class VideoPreviewService {
    constructor(private http: HttpClient) {}

    /**
     * Retrieves a YouTube videos metadata via the YouTube API
     */
    lookupByUrl(url: string): Observable<VideoMetadata> {
        return this.http
            .post<{
                data: {
                    attributes: VideoMetadata;
                };
            }>('/v2/videos/preview', {
                url,
            })
            .pipe(
                map(({ data: { attributes } }) => attributes),
                catchError((error) => {
                    if (error instanceof HttpErrorResponse) {
                        switch (error.status) {
                            case 400:
                                throw new InvalidVideoUrlError(`Invalid video URL: ${url}`, url);
                            case 404:
                                throw new VideoNotFoundError(`Could not find metadata for this video: ${url}`, url);
                            case 503:
                                throw new VideoPreviewServiceUnavailableError(
                                    `Video preview service currently unavailable.`,
                                    url
                                );
                        }
                    }
                    throw error;
                })
            );
    }
}
