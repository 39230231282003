<form #form="ngForm" class="video-lookup loadable" [class.loading]="isLoading">
    <div>
        <mat-form-field class="width-full">
            <mat-label>{{ 'videos.youTubeVideoUrl' | translate }}</mat-label>
            <input
                matInput
                name="video"
                type="text"
                placeholder="https://www.youtube.com/watch?v="
                [(ngModel)]="videoUrl"
            />
            <mat-hint><span [innerHtml]="'videos.allowEmbedding' | translate"></span></mat-hint>
            @if (videoInput && videoInput.invalid) {
                <mat-error>
                    @if (videoInput.hasError('invalid-url')) {
                        {{ 'videos.oopsItLooksLikeThisIsAnInvalidUrl' | translate }}
                    } @else if (videoInput.hasError('not-found')) {
                        {{ 'videos.oopsWeCouldntFindAVideoAtThisUrl' | translate }}
                    } @else if (videoInput.hasError('service-unavailable')) {
                        {{ 'videos.weCantLookupVideoPreviewsRightNowPleaseTryAgainInAFewSeconds' | translate }}
                    } @else if (videoInput.hasError('private-access')) {
                        {{ 'videos.privateVideoError' | translate }}
                    }
                </mat-error>
            }
        </mat-form-field>
    </div>

    <ng-content select=".info"></ng-content>

    @if (videoMetadata) {
        <app-video-preview class="w-100" [videoMetadata]="videoMetadata"></app-video-preview>
    }
</form>
