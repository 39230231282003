@if (videoMetadata) {
    @if (videoMetadata.privacyStatus !== 'private') {
        <div class="text-small">{{ 'videos.videoPreview' | translate }}</div>
        <a [href]="videoMetadata.url" target="_blank">
            <img
                class="margin-top-1 margin-bottom-2"
                [src]="videoMetadata.thumbnailUrl"
                alt="{{ 'videos.aVideoThumbnail' | translate: { videoTitle: videoMetadata.title } }}"
            />
        </a>
    }
    @if (videoMetadata.privacyStatus === 'public') {
        <ui-tip><span [innerHTML]="'videos.thisVideoIsPublic' | translate"></span></ui-tip>
    }
}
