import { NgModule } from '@angular/core';

import * as Services from '@videos/services';
import VideoLookupComponent from './components/video-lookup/video-lookup.component';
import VideoPreviewComponent from './components/video-preview/video-preview.component';

@NgModule({
    imports: [VideoPreviewComponent, VideoLookupComponent],
    providers: [...Object.values(Services)],
    exports: [VideoLookupComponent, VideoPreviewComponent],
})
export class VideosModule {}
